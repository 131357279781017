import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CommonContext } from '../../../context';
import Layout from '../../../components/layout';
import { capitalizeFirstLetter, sortByCustomOrder } from '../../../utils';
import FeaturedPosts from '../../../components/FeaturedPosts';
import BlogCategoryTab from '../../../components/BlogCategoryTab';
import BlogCard, { CARD_VARIANTS } from '../../../components/BlogCard';
import BlogCategoryBanner from '../../../components/BlogCategoryBanner';

export const query = graphql`
  query ($slug: String!) {
    wpCategory(slug: { eq: $slug }) {
      name
      slug
      categoryBannerImages {
        bannerLinkUrl
        categoryBannerImage {
          altText
          gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
        }
      }
    }
    allWpCategory {
      nodes {
        slug
        name
      }
    }
    allWpPost(
      sort: { fields: modified, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        slug
        title
        excerpt
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        postMetadata {
          order
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }
`;

function CategoryArchive({ data, location }) {
  const category = data.wpCategory;
  const allCategories = data.allWpCategory.nodes;
  const blogData = sortByCustomOrder(
    data.allWpPost.nodes,
    'postMetadata.order',
  );

  const featuredPosts = blogData.slice(0, 4);
  const remainingPosts = blogData.slice(4);

  const [showAll, setShowAll] = useState(false);

  const postsToDisplay = showAll ? remainingPosts : remainingPosts.slice(0, 6);
  const shouldShowButton = remainingPosts.length > 6;

  const hasCategoryBanner = category?.categoryBannerImages.categoryBannerImage;
  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title={`Category: ${category.name}`} location={location}>
        <div className="w-full max-w-6xl px-6 m-auto my-4 md:my-8 md:px-4 bg-neutral">
          <BlogCategoryTab
            allCategories={allCategories}
            activeCategory={category}
          />

          <div className="mt-5 mb-6 text-3xl font-bold text-center md:mt-12 md:mb-10 md:text-5xl text-accent">
            {capitalizeFirstLetter(category.name)}
          </div>

          <FeaturedPosts featuredPosts={featuredPosts} />
          <div className={hasCategoryBanner ? 'my-16 sm:mt-24 md:mt-48' : ''}>
            {hasCategoryBanner && (
              <BlogCategoryBanner {...category.categoryBannerImages} />
            )}
          </div>
          <div
            className={hasCategoryBanner ? 'mb-24 sm:mt-24 md:mt-48' : 'my-16'}
          >
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {postsToDisplay.map((post) => {
                return (
                  <Link to={`/blog/${post.slug}/`} key={post.slug}>
                    <BlogCard
                      className="w-full h-full"
                      variant={CARD_VARIANTS.SMALL}
                      post={post}
                    />
                  </Link>
                );
              })}
            </div>
            {shouldShowButton && (
              <div className="mt-16 text-center">
                <button
                  className="px-8 py-4 font-semibold transition-all duration-300 border rounded-lg border-primary text-primary hover:bg-primary-0 hover:shadow-xl"
                  onClick={handleToggleShow}
                >
                  {showAll ? 'Show Less' : 'Show More'}
                </button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

CategoryArchive.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default CategoryArchive;
