import React from 'react';
import PropTypes from 'prop-types';
import BlogCard, { CARD_VARIANTS } from './BlogCard';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.175,
    },
  },
};

const itemVariants = {
  hidden: { y: '1.75rem', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.8,
      stiffness: 260,
      damping: 15,
    },
  },
};

const FeaturedPosts = ({ featuredPosts }) => {
  return (
    <motion.div
      className="grid grid-cols-1 gap-8 md:gap-6 lg:grid-cols-2 lg:gap-11"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {featuredPosts.map((post, i) => {
        return (
          <motion.div
            key={post.slug}
            className={`${i === 0 ? 'md:row-span-3' : 'w-full h-full'} `}
            variants={itemVariants}
          >
            {i === 0 ? (
              <Link to={`/blog/${post.slug}/`} className="cursor-pointer">
                <BlogCard
                  variant={CARD_VARIANTS.FEATURED}
                  post={post}
                  className="md:row-span-3"
                />
              </Link>
            ) : (
              <Link to={`/blog/${post.slug}/`} className="cursor-pointer">
                <BlogCard
                  variant={CARD_VARIANTS.HORIZONTAL}
                  post={post}
                  className="w-full h-full"
                />
              </Link>
            )}
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default FeaturedPosts;

FeaturedPosts.propTypes = {
  featuredPosts: PropTypes.array,
};
