import PropTypes from 'prop-types';
import React, { useState, memo, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { navigate } from 'gatsby';
import { PaginationArrowLeft, PaginationArrowRight } from '../custom-icons';

const globalState = {
  scrollPosition: 0,
  activeTabIndex: 0,
};

const TabButton = memo(({ category, isActive, onClick, index }) => (
  <motion.button
    onClick={() => onClick(category.slug, index)}
    className={`
      relative rounded-full px-4 py-2 text-sm font-medium whitespace-nowrap h-[40px]
      ${
        isActive
          ? 'text-neutral-0 bg-primary-900 md:bg-transparent'
          : 'bg-neutral-2'
      }
    `}
    transition={{ type: 'all', bounce: 0.2, duration: 0.5 }}
    whileHover={{
      scale: 1.1,
      transition: { duration: 0.125 },
    }}
    layout
  >
    {isActive && (
      <motion.span
        layoutId="bubble"
        className="absolute inset-0 z-10 hidden rounded-full bg-primary-900 md:block"
        transition={{ type: 'spring', bounce: 0.2, duration: 0.5 }}
      />
    )}
    <span className="relative z-20">{category.name}</span>
  </motion.button>
));

TabButton.displayName = 'TabButton';
TabButton.propTypes = {
  category: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ScrollButton = memo(({ show, onClick, icon: Icon }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    className={`flex items-center justify-center flex-shrink-0 w-12 h-12 rounded-full ${
      show
        ? 'bg-neutral-2 cursor-pointer'
        : 'bg-neutral-2 opacity-50 cursor-not-allowed'
    }`}
    onClick={onClick}
    disabled={!show}
  >
    <Icon className="w-6 h-6 mx-auto text-accent" />
  </motion.button>
));

ScrollButton.displayName = 'ScrollButton';
ScrollButton.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
};

const BlogCategoryTab = memo(({ allCategories, activeCategory }) => {
  const [translateX, setTranslateX] = useState(globalState.scrollPosition);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  const [hasOverflow, setHasOverflow] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const tabRefs = useRef(new Map());

  const activeIndex = allCategories.findIndex(
    (cat) => cat.slug === activeCategory.slug,
  );

  const scrollTabToCenter = (index) => {
    if (!containerRef.current || !contentRef.current) return;

    const container = containerRef.current;
    const tabElement = tabRefs.current.get(index);

    if (!tabElement) return;

    const containerWidth = container.offsetWidth;
    const tabRect = tabElement.getBoundingClientRect();

    const tabOffsetLeft = tabElement.offsetLeft;
    const tabWidth = tabRect.width;

    const desiredPosition = -(
      tabOffsetLeft -
      containerWidth / 2 +
      tabWidth / 2
    );

    const maxScroll = -(contentRef.current.scrollWidth - containerWidth);

    const boundedPosition = Math.max(Math.min(desiredPosition, 0), maxScroll);

    setTranslateX(boundedPosition);
    globalState.scrollPosition = boundedPosition;
  };

  useEffect(() => {
    scrollTabToCenter(activeIndex);
  }, [activeCategory.slug]);

  const checkOverflow = () => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.scrollWidth;
      const hasOverflowContainer = contentWidth > containerWidth;
      const maxScroll = -(contentWidth - containerWidth);
      setHasOverflow(hasOverflowContainer);
      setShowArrows({
        left: hasOverflowContainer && translateX < -10,
        right: hasOverflowContainer && translateX > maxScroll + 10,
      });
    }
  };

  useEffect(() => {
    checkOverflow();
    const handleResize = () => {
      checkOverflow();
      scrollTabToCenter(activeIndex);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [translateX, activeIndex]);

  const handleScroll = (direction) => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.scrollWidth;
      const maxScroll = -(contentWidth - containerWidth);
      const scrollAmount = containerWidth * 0.5;

      let newTranslateX;
      if (direction === 'left') {
        newTranslateX = Math.min(translateX + scrollAmount, 0);
      } else {
        newTranslateX = Math.max(translateX - scrollAmount, maxScroll);
      }

      setTranslateX(newTranslateX);
      globalState.scrollPosition = newTranslateX;
    }
  };

  const handleTabClick = (categorySlug, index) => {
    globalState.activeTabIndex = index;
    scrollTabToCenter(index);
    navigate(`/blog/category/${categorySlug}/`);
  };

  return (
    <div className="w-full m-auto bg-neutral-900 rounded-xl">
      <div className="flex items-center w-full">
        <ScrollButton
          show={showArrows.left}
          onClick={() => handleScroll('left')}
          icon={PaginationArrowLeft}
        />

        <div ref={containerRef} className="w-full overflow-hidden">
          <AnimatePresence mode="wait">
            <motion.div
              ref={contentRef}
              animate={{ x: translateX }}
              initial={false}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                duration: 0.5,
              }}
              className={`flex items-center gap-2 px-3 md:gap-3 h-14 ${
                hasOverflow ? '' : 'lg:justify-center'
              }`}
            >
              {allCategories.map((category, index) => (
                <motion.div
                  key={category.slug}
                  layout
                  ref={(el) => {
                    if (el) tabRefs.current.set(index, el);
                    else tabRefs.current.delete(index);
                  }}
                >
                  <TabButton
                    category={category}
                    isActive={category.slug === activeCategory.slug}
                    onClick={handleTabClick}
                    index={index}
                  />
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>

        <ScrollButton
          show={showArrows.right}
          onClick={() => handleScroll('right')}
          icon={PaginationArrowRight}
        />
      </div>
    </div>
  );
});

BlogCategoryTab.displayName = 'BlogCategoryTab';

BlogCategoryTab.propTypes = {
  allCategories: PropTypes.array.isRequired,
  activeCategory: PropTypes.object.isRequired,
};

export default BlogCategoryTab;
